import { OrthodonticsOrderedPhasesKeys } from '../../../orthodontics/components/layout';
import { useCaseChanges } from '../../shared';
import { useValidateTeethSegmentation } from './useValidateTeethSegmentation';

export interface PhaseChangeValidationHook {
  isValidPhase(): Promise<boolean>;
}

const defaultPhaseChangeValidationHook: Readonly<PhaseChangeValidationHook> = {
  isValidPhase: async (): Promise<boolean> => {
    return Promise.resolve(true);
  }
};

export function usePhaseChangeValidation() {
  const { getRouteKey } = useCaseChanges();

  const orthodonticsPhasesValidationDictionary: { [key: string]: PhaseChangeValidationHook } = {
    [OrthodonticsOrderedPhasesKeys.STLLoad]: defaultPhaseChangeValidationHook,
    [OrthodonticsOrderedPhasesKeys.TeethSegmentation]: useValidateTeethSegmentation(),
    [OrthodonticsOrderedPhasesKeys.AxisAndRoots]: defaultPhaseChangeValidationHook,
    [OrthodonticsOrderedPhasesKeys.Treatment]: defaultPhaseChangeValidationHook,
    [OrthodonticsOrderedPhasesKeys.Publish]: defaultPhaseChangeValidationHook
  };

  const getPhaseChangeValidation = () => {
    const key = getRouteKey();
    if (!key) return defaultPhaseChangeValidationHook;
    const phaseValidationFunction = orthodonticsPhasesValidationDictionary[key];
    return phaseValidationFunction;
  };

  return { getPhaseChangeValidation };
}
