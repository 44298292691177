import { inject, injectable } from 'inversify';
import { INJECTED_TYPES } from '../../common/ioc/ioc.types';
import { CaseType } from '../../models';
import * as clinic from '../clinic';
import type { IHttpService } from '../http';
import type { IImplantsService } from '../implants';
import {
  CaseVersion,
  CaseVersionListItemDTO,
  CaseVersionsListDTO,
  MinorVersion,
  PatientCase,
  PatientCaseDTO,
  PatientCaseDTOData
} from './case';
import { CaseBuilder } from './case.builder';
import { ICasesService } from './icases.service';
import { isSaasApiEnabled } from '../settings';

function PatientCaseDTOToPatientCase(
  patientCaseDTO: PatientCaseDTOData,
  clinic: clinic.Clinic,
  type?: CaseType
): PatientCase {
  if (!isSaasApiEnabled()) {
    return new CaseBuilder()
      .withGeneralData(patientCaseDTO, clinic, type)
      .withSpecificInfo(patientCaseDTO)
      .withCapturesData(patientCaseDTO)
      .withPhotographsData(patientCaseDTO)
      .build();
  } else {
    return new CaseBuilder()
      .withGeneralData(patientCaseDTO, clinic, type)
      .withCapturesData(patientCaseDTO)
      .withPhotographsData(patientCaseDTO)
      .build();
  }
}

@injectable()
export class CasesService implements ICasesService {
  @inject(INJECTED_TYPES.IHttpService) private _httpService: IHttpService;
  @inject(INJECTED_TYPES.IImplantsService) private _implantsService: IImplantsService;

  public versionIdUrlParamIndex = 'versionId';
  private readonly apiEndpoint = '/api/product-cases';
  private readonly saasApiEndpoint = '/saas-api/tools/treatments';

  public async getById(id: string, versionId: string): Promise<PatientCase> {
    return !isSaasApiEnabled() ? await this.getByIdLegacy(id, versionId) : await this.getByIdSaaS(id, versionId);
  }

  private async getByIdLegacy(id: string, versionId: string): Promise<PatientCase> {
    const url = `${this.apiEndpoint}/${id}`;
    const caseDTO = (await this._httpService.get<PatientCaseDTO>(url))?.data.data;

    const clinicDTO = caseDTO?.generalInfo?.clinic;
    const clinic: clinic.Clinic = {
      address: `${clinicDTO.address}, ${clinicDTO.addressNumber} ${clinicDTO.addressExtra} - ${clinicDTO.postalCode} ${clinicDTO.city.name} (${clinicDTO.city.province.name})`,
      name: clinicDTO.name
    };

    const type = CaseType.Orthodontics;
    // currently not needed, but keep this commented line as reminder when Surgeries project be restarted
    // const implants = await this._implantsService.getAll(id);

    return PatientCaseDTOToPatientCase(caseDTO, clinic, type);
  }

  private async getByIdSaaS(id: string, versionId: string): Promise<PatientCase> {
    const url = `${this.saasApiEndpoint}/${id}/versions/${versionId}`;
    const caseDTO = (await this._httpService.get<PatientCaseDTOData>(url))?.data;
    caseDTO.generalInfo.patient.firstName = caseDTO.generalInfo.patient.name.split(' ')[0];
    caseDTO.generalInfo.patient.lastName = caseDTO.generalInfo.patient.name.split(' ').slice(1).join(' ');

    const clinicDTO = caseDTO?.generalInfo?.clinic;
    const clinic: clinic.Clinic = {
      address: `${clinicDTO.address} - ${clinicDTO.postalCode}`,
      name: clinicDTO.name
    };

    const type = CaseType.Orthodontics;
    // currently not needed, but keep this commented line as reminder when Surgeries project be restarted
    // const implants = await this._implantsService.getAll(id);

    return PatientCaseDTOToPatientCase(caseDTO, clinic, type);
  }

  public async update(id: string, versionId: string, data: string): Promise<PatientCase> {
    return !isSaasApiEnabled()
      ? await this.updateLegacy(id, versionId, data)
      : await this.updateSaaS(id, versionId, data);
  }

  private async updateLegacy(id: string, versionId: string, data: string): Promise<PatientCase> {
    const toSend: PatientCaseDTOData = {
      meta: [data]
    };

    const url = `${this.apiEndpoint}/${id}`;
    const result = (await this._httpService.patch<PatientCaseDTO>(url, toSend)).data.data;

    const clinicDTO = result.generalInfo?.clinic;
    const clinic = {
      address: `${clinicDTO.address}, ${clinicDTO.addressNumber} ${clinicDTO.addressExtra} - ${clinicDTO.postalCode} ${clinicDTO.city.name} (${clinicDTO.city.province.name})`,
      name: clinicDTO.name
    };

    // currently not needed, but keep this commented line as reminder when Surgeries project be restarted
    // const implants = await this._implantsService.getAll(id);
    return PatientCaseDTOToPatientCase(result, clinic);
  }

  private async updateSaaS(id: string, versionId: string, data: string): Promise<PatientCase> {
    const toSend: PatientCaseDTOData = {
      meta: [data]
    };

    const url = `${this.saasApiEndpoint}/${id}/versions/${versionId}`;
    const result = (await this._httpService.patch<PatientCaseDTOData>(url, toSend)).data;

    const clinicDTO = result.generalInfo?.clinic;
    const clinic = {
      address: `${clinicDTO.address} - ${clinicDTO.postalCode}`,
      name: clinicDTO.name
    };

    // currently not needed, but keep this commented line as reminder when Surgeries project be restarted
    // const implants = await this._implantsService.getAll(id);
    return PatientCaseDTOToPatientCase(result, clinic);
  }

  public async getVersionsByCaseId(caseId: string): Promise<CaseVersion[]> {
    return !isSaasApiEnabled()
      ? await this.getVersionsByCaseIdLegacy(caseId)
      : await this.getVersionsByCaseIdSaaS(caseId);
  }

  private async getVersionsByCaseIdLegacy(caseId: string): Promise<CaseVersion[]> {
    const url = `/api/product-cases/${caseId}/treatment-plans?include=changeDerivatedFrom,changeRequests`;
    const versionItems = (await this._httpService.get<CaseVersionsListDTO>(url)).data.data;

    const versions: CaseVersion[] = versionItems.map((versionDTO: CaseVersionListItemDTO) =>
      this.mapCaseVersionListItemDTOToCaseVersion(versionDTO)
    );

    return versions;
  }

  private async getVersionsByCaseIdSaaS(caseId: string): Promise<CaseVersion[]> {
    const url = `/saas-api/tools/treatments/${caseId}/versions`;
    const versionItems = (await this._httpService.get<CaseVersionListItemDTO[]>(url)).data;

    const versions: CaseVersion[] = versionItems.map((versionDTO: CaseVersionListItemDTO) =>
      this.mapCaseVersionListItemDTOToCaseVersion(versionDTO)
    );
    return versions;
  }

  public async getCaseVersion(caseId: string, versionId: string): Promise<CaseVersion> {
    return !isSaasApiEnabled()
      ? await this.getCaseVersionLegacy(caseId, versionId)
      : await this.getCaseVersionSaaS(caseId, versionId);
  }

  private async getCaseVersionLegacy(caseId: string, versionId: string): Promise<CaseVersion> {
    const url = `/api/product-cases/${caseId}/treatment-plans/${versionId}`;
    return (await this._httpService.get<CaseVersion>(url)).data;
  }

  private async getCaseVersionSaaS(caseId: string, versionId: string): Promise<CaseVersion> {
    const url = `/saas-api/tools/treatments/${caseId}/versions/${versionId}`;
    return (await this._httpService.get<CaseVersion>(url)).data;
  }

  public async editCaseVersion(caseId: string, version: CaseVersion): Promise<void> {
    return !isSaasApiEnabled()
      ? await this.editCaseVersionLegacy(caseId, version)
      : await this.editCaseVersionSaaS(caseId, version);
  }

  private async editCaseVersionLegacy(caseId: string, version: CaseVersion): Promise<void> {
    const url = `/api/product-cases/${caseId}/treatment-plans/${version.id}`;

    const toSend: CaseVersion = version;
    await this._httpService.patch(url, toSend);
  }

  private async editCaseVersionSaaS(caseId: string, version: CaseVersion): Promise<void> {
    const url = `/saas-api/tools/treatments/${caseId}/versions/${version.id}`;

    const toSend: CaseVersion = version;
    await this._httpService.patch(url, toSend);
  }

  public async createCaseVersion(caseId: string, version: CaseVersion): Promise<void> {
    const url = `/api/product-cases/${caseId}/treatment-plans`;
    await this._httpService.post(url, version);
  }

  public async deleteCaseVersion(caseId: string, versionId: string): Promise<void> {
    const url = `/proposal/product-cases/${caseId}/versions/${versionId}`;
    await this._httpService.delete(url);
  }

  public async cloneCaseVersion(caseId: string, versionId: string): Promise<void> {
    const url = `/api/product-cases/${caseId}/treatment-plans/${versionId}/clone`;
    await this._httpService.post(url, {});
  }

  // TODO: numberOfSteps is only used for mock purposes. Delete it when real endpoint is done
  public async getMinorVersions(caseId: string, versionId: string, numberOfSteps: number): Promise<MinorVersion[]> {
    const url = `/mock/product-case/${caseId}/versions/${versionId}/minor-versions`;
    return (await this._httpService.get<MinorVersion[]>(url, { steps: numberOfSteps.toString() })).data;
  }

  private mapCaseVersionListItemDTOToCaseVersion = (caseVersionItemListDTO: CaseVersionListItemDTO): CaseVersion => ({
    caseId: caseVersionItemListDTO.caseId,
    createdAt: caseVersionItemListDTO.createdAt,
    id: caseVersionItemListDTO.id,
    name: caseVersionItemListDTO.name,
    result: caseVersionItemListDTO.result,
    state: caseVersionItemListDTO.state,
    updatedAt: caseVersionItemListDTO.updatedAt,
    plan: caseVersionItemListDTO.plan || null,
    segmentation: caseVersionItemListDTO.segmentation || null,
    clientFinalPositionStep: caseVersionItemListDTO.createdFromChange?.clientFinalPositionStep || null,
    permissions: caseVersionItemListDTO.permissions
  });
}
