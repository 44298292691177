import { useCallback, useEffect, useMemo } from 'react';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { useBoundStore } from '../../../surgeries/stores/useStore';
import { AttachesInputDTO, ICalculationAttachesService } from '../../../shared';
import { container, INJECTED_TYPES } from '../../../common';
import { useAttaches, useUtils } from '../../shared';
import { useDentalMovementsSnapshot } from './useDentalMovementsSnapshot';
import { useCommonBoundStore } from '../../../common/stores/useStore';

export function useAttachesManager() {
  const {
    hoveredAttach,
    areAttachesCalculating,
    setAreAttachesCalculating,
    areAttachesSet,
    setAreAttachesSet,
    caseStatusFromStore,
    setShowAttachesPanel
  } = useOrthBoundStore((state) => ({
    hoveredAttach: state.hoveredAttach,
    areAttachesCalculating: state.areAttachesCalculating,
    setAreAttachesCalculating: state.setAreAttachesCalculating,
    areAttachesSet: state.areAttachesSet,
    setAreAttachesSet: state.setAreAttachesSet,
    caseStatusFromStore: state.caseStatus,
    setShowAttachesPanel: state.setShowAttachesPanel
  }));
  const { setWebBusy } = useBoundStore((state) => ({
    setMessageInfo: state.setMessageInfo,
    setWebBusy: state.setWebBusy
  }));
  const isCaseReseting = useCommonBoundStore((state) => state.isCaseReseting);

  const calculateAttachesService = container.get<ICalculationAttachesService>(
    INJECTED_TYPES.ICalculationAttachesService
  );

  const { updateMovements, fixFloat, getCurrentPhaseStageByRoute } = useUtils();
  const { typeTooltip, positionTooltip } = useAttaches();
  const { updateDentalMovementsSnapshot } = useDentalMovementsSnapshot();

  useEffect(() => {
    if (areAttachesCalculating) {
      const sendDataToAIAndApply = async () => {
        await calculateAttaches();
      };

      sendDataToAIAndApply();
    }
    setAreAttachesCalculating(false);
  }, [areAttachesCalculating]);

  const calculateAttaches = async () => {
    try {
      setWebBusy(true);

      const resultInputAttach: AttachesInputDTO = await window.App.webEventsProxy.attachments.getInputAttachments();
      const responseIA = await calculateAttachesService.getAIAutomaticAttaches(resultInputAttach);
      await window.App.webEventsProxy.attachments.updateAttachmentsFromAI(responseIA);
      updateMovements();
      setAreAttachesSet(true);
      updateDentalMovementsSnapshot();
      setShowAttachesPanel(false);
      window.App.webEventsProxy.layers.showAttachments(true);
    } catch (error) {
      console.error(error);
    } finally {
      setWebBusy(false);
    }
  };

  const thicknessTextTooltip = useMemo(() => {
    const thicknessValue =
      hoveredAttach?.thickness.currentThickness !== undefined
        ? `${fixFloat(hoveredAttach?.thickness.currentThickness)}`
        : '--';
    return `${typeTooltip} ${thicknessValue} mm`;
  }, [hoveredAttach]);

  const getPhaseCompletionDate = useCallback(
    (phaseKey: string) => {
      if (!caseStatusFromStore) {
        return;
      }
      const phase = caseStatusFromStore.phases.find((p) => p.name === phaseKey);
      return phase && phase.completionDate ? new Date(phase.completionDate).toLocaleDateString('es-es') : '';
    },
    [caseStatusFromStore]
  );

  const hasPhaseCompletionDate = useCallback((): boolean => {
    const currentPhase = getCurrentPhaseStageByRoute();
    if (currentPhase === undefined) {
      return false;
    }
    const completionDate = getPhaseCompletionDate(currentPhase);

    return completionDate !== '';
  }, [getPhaseCompletionDate]);

  const checkIfAttachesAreSet = (): boolean => {
    return areAttachesSet || (areAttachesSet == null && hasPhaseCompletionDate() && !isCaseReseting);
  };

  return {
    positionTooltip,
    thicknessTextTooltip,
    hoveredAttach,
    areAttachesSet,
    setAreAttachesSet,
    checkIfAttachesAreSet,
    calculateAttaches
  };
}
