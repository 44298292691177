import { DentalMovementPair } from '../../../common';
import { useOrthBoundStore } from '../../../orthodontics/stores/useStore';
import { isEqual } from 'lodash';

export function useDentalMovementsSnapshot() {
  const { dentalMovementsSnapshot, setDentalMovementsSnapshot } = useOrthBoundStore((state) => ({
    dentalMovementsSnapshot: state.dentalMovementsSnapshot,
    setDentalMovementsSnapshot: state.setDentalMovementsSnapshot
  }));

  const getDentalMovementsFromEvergine = () => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    return {
      upperDentalMovements: upperMovements,
      lowerDentalMovements: lowerMovements
    } as DentalMovementPair;
  };

  const updateDentalMovementsSnapshot = () => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    setDentalMovementsSnapshot({
      lowerDentalMovements: lowerMovements,
      upperDentalMovements: upperMovements
    });
  };

  const haveBeenMadeChangesInMovements = () => {
    if (dentalMovementsSnapshot === null) {
      return false;
    }
    const movementsFromEvergine = getDentalMovementsFromEvergine();
    return !isEqual(movementsFromEvergine, dentalMovementsSnapshot);
  };

  return {
    updateDentalMovementsSnapshot,
    haveBeenMadeChangesInMovements
  };
}
