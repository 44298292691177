import axios from 'axios';
import { Settings } from './settings';

export async function loadSettings() {
  return (await axios.get<Settings>('/config'))?.data;
}

export function isSaasApiEnabled() {
  const params = new URLSearchParams(window.location.search);
  const saasApiParam = params.get('saasApi');

  if (saasApiParam === 'true') {
    sessionStorage.setItem('saasApi', 'true');
    return true;
  }

  const storedSaasApi = sessionStorage.getItem('saasApi');
  if (storedSaasApi) {
    return true;
  } else {
    sessionStorage.removeItem('saasApi');
    return false;
  }
}
