import { useCallback } from 'react';
import { useCommonBoundStore } from '../../common/stores/useStore';
import { OrthodonticsOrderedPhasesKeys } from '../../orthodontics/components/layout';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { TypesActions } from '../../surgeries/components/layout';
import { useSaveAxisAndRoots, useSaveSegmentation, useSaveTreatment } from '../orthodontics';
import { useSTLLoad } from '../useSTLLoad';
import { useCaseChanges } from './useCaseChanges';

export interface SaveChangesHook {
  saveChanges(isNextPhase: boolean): Promise<boolean>;
}

// This is a default hook that will be used when no hook is found for the current phase
// or, when save changes is not required (going back from a step without saving)
const defaultSaveChangesHook: Readonly<SaveChangesHook> = {
  saveChanges: async (): Promise<boolean> => {
    return Promise.resolve(true);
  }
};

export function useModalSaveChanges(param?: TypesActions) {
  const { getRouteKey } = useCaseChanges();
  const currentVersion = useOrthBoundStore((state) => state.currentVersion);
  const { isCaseReseting, upperFilesToUpload, lowerFilesToUpload, keysFilesToDelete } = useCommonBoundStore(
    (state) => ({
      isCaseReseting: state.isCaseReseting,
      upperFilesToUpload: state.upperFilesToUpload,
      lowerFilesToUpload: state.lowerFilesToUpload,
      keysFilesToDelete: state.keysFilesToDelete
    })
  );

  const orthodonticsPhasesDictionary: { [key: string]: SaveChangesHook } = {
    [OrthodonticsOrderedPhasesKeys.STLLoad]: useSTLLoad(),
    [OrthodonticsOrderedPhasesKeys.TeethSegmentation]: useSaveSegmentation(),
    [OrthodonticsOrderedPhasesKeys.AxisAndRoots]: useSaveAxisAndRoots(),
    [OrthodonticsOrderedPhasesKeys.Treatment]: useSaveTreatment(),
    [OrthodonticsOrderedPhasesKeys.Publish]: defaultSaveChangesHook
  };

  const getPhaseSaveChange = useCallback(() => {
    const key = getRouteKey();
    if (!key && !param) return defaultSaveChangesHook;
    const phaseFunction = orthodonticsPhasesDictionary[key];
    return phaseFunction;
  }, [currentVersion, param, isCaseReseting, upperFilesToUpload, lowerFilesToUpload, keysFilesToDelete]);

  return { getPhaseSaveChange };
}
