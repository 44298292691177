import { initializeEvergineBase } from 'evergine-react';
import { useOrthBoundStore } from '../../orthodontics/stores/useStore';
import { ICommandInvoker, IErrorHandlerService, MovementsTableDto } from '../../shared';
import { useBoundStore } from '../../surgeries/stores/useStore';
import { INJECTED_TYPES } from '../ioc';
import { container } from '../ioc/ioc.config';
import { debounceOutsideComponent, debouncedSetState } from '../utils';
import { EVERGINE_ASSEMBLY_NAME, EVERGINE_CLASS_NAME, EVERGINE_LOADING_BAR_ID } from './config';
import {
  ActionCommand,
  AttachmentDto,
  AttachmentPositionInfo,
  AttachmentRemoveMode,
  AttachmentThicknessDto,
  AttachmentType,
  BoltonDataDto,
  CameraPosition,
  CapturePosition,
  Color,
  DentalMovementGroup,
  DentalMovementPair,
  InputAttachments,
  InputIntermediateSteps,
  IntermediateStepsProcessingResult,
  IprLabel,
  IprLabelPosition,
  MatchingInfo,
  MatchingModelPoint,
  Model,
  MovementType,
  OutputAttachments,
  OutputIntermediateSteps,
  ResetArch,
  SegmentationNumerationChangeEvalResult,
  SegmentationNumerationStrategy,
  SegmentationProcessingResult,
  Stage,
  TooltipAttach,
  Tooth,
  ToothChange,
  ToothHoverData,
  ToothRotationAxisChange,
  ToothRotationAxisPrediction,
  ToothState,
  ToothTransformInfo
} from './types';
import EvergineCommand from '../../shared/commands/evergineCommand';
import { useToothTransformStore } from '../../orthodontics/stores/useToothTransformStore';

declare global {
  let Blazor: { start(): Promise<void> };

  let Module: {
    FS: {
      readdir(dirPath: string): string[];
      mkdir(dirPath: string): void;
      rmdir(dirPath: string): void;
      isDir(dirPath: string): boolean;
      isFile(filePath: string): boolean;
      unlink(filePath: string): void;
      lookupPath(filePath: string): any;
      stat(path: string): void;
      writeFile(filePath: string, data: Int8Array): void;
      readFile(filePath: string): any;
      open(filePath: string): any;
    };
  };

  interface Window {
    testE2E: boolean;
  }

  interface AppEventsListenerForCommon {
    onChangeBusyState: (busy: boolean) => void;
    onNewActionCommand: (command: ActionCommand) => void;
  }

  interface AppEventsListenerForAxisAndRoots {
    onToothRotationAxisChanged: (change: ToothRotationAxisChange) => void;
    onRealRootsProcessed: (zipFileRoute: string, teethArch: CapturePosition) => void;
  }

  interface AppEventsListenerForAttachments {
    onAttachmentHovered: (info: AttachmentPositionInfo) => void;
    onAttachmentLeft: (info: AttachmentDto) => void;
    onAttachmentSelected: (info: AttachmentPositionInfo) => void;
    onAttachmentMoved: (attachment: AttachmentDto) => void;
  }

  interface AppEventsListenerForIprs {
    onIprLabelPositionChanged: (iprLabelPosition: IprLabelPosition) => void;
    onLabelAdded: (label: IprLabel) => void;
    onLabelHovered: (iprLabelPosition: IprLabelPosition) => void;
    onLabelLeft: (iprLabelPosition: IprLabelPosition) => void;
    onLabelSelected: (iprLabelPosition: IprLabelPosition) => void;
  }

  interface AppEventsListenerForDentalMovements {
    onTeethSelected: (teethFdis: number[]) => void;
    onToothHovered: (hoverState: ToothHoverData) => void;
    onToothLeft: (leftState: ToothHoverData) => void;
    onToothTransformChanged: (toothTransform: ToothTransformInfo) => void;
  }

  interface AppEventsListenerForSegmentation {
    onBrushActivationChange: (activated: boolean) => void;
    onEraserActivationChange: (activated: boolean) => void;
  }

  interface AppEventsListenerForSurgeries {
    onChangeMatchingPoint: (matchingModelPoint: MatchingModelPoint) => void;
  }

  interface AppEventsListener {
    attachments: AppEventsListenerForAttachments;
    axisAndRoots: AppEventsListenerForAxisAndRoots;
    common: AppEventsListenerForCommon;
    iprs: AppEventsListenerForIprs;
    movements: AppEventsListenerForDentalMovements;
    segmentation: AppEventsListenerForSegmentation;
    surgeries: AppEventsListenerForSurgeries;
  }

  interface WebEventsProxyForLayers {
    setGumsOpacity: (value: number) => void;
    setRootsOpacity: (value: number) => void;
    setTeethOpacity: (value: number) => void;
    showArches: (arches: CapturePosition) => void;
    showAttachments: (show: boolean) => void;
    showAxis: (showAxis: boolean) => void;
    showGrid: (enabled: boolean) => void;
    showGums: (show: boolean) => void;
    showIprs: (show: boolean) => void;
    showInterdentalDistances: (show: boolean) => void;
    showOcclusogram: (show: boolean) => void;
    setOcclusogramBlend: (blend: number) => void;
    showRoots: (show: boolean) => void;
    showTeeth: (show: boolean) => void;
    showTeethAxis: (show: boolean) => void;
    showTADs: (show: boolean) => void;
  }

  interface WebEventsProxyForSegmentation {
    applyNumerationChange: (fdiFrom: number, fdiTo: number, strategy: SegmentationNumerationStrategy) => Promise<void>;
    evaluateNumerationChange: (
      fdiFrom: number,
      fdiTo: number,
      strategy: SegmentationNumerationStrategy
    ) => Promise<SegmentationNumerationChangeEvalResult>;
    processSegmentation: () => Promise<SegmentationProcessingResult>;
    setBrushSize: (size: number) => void;
    setColorsByFdi: (colors: { [fdi: number]: string }) => void;
    setPainterFdi: (fdi: number) => void;
    setSegmentationData: (data: { faces: number[]; teethArch: CapturePosition }) => void;
    setInitialSegmentationData: (data: { faces: number[]; teethArch: CapturePosition }) => void;
    queryToothFDI: () => number;
  }

  interface WebEventsProxyForAttachments {
    getInputAttachments: () => Promise<InputAttachments>;
    updateAttachmentsFromAI: (attachments: OutputAttachments) => Promise<void>;
    addAttachment: (fdi: number, type: AttachmentType) => AttachmentDto;
    changeAttachmentType: (fdi: number, type: AttachmentType) => AttachmentDto;
    removeAttachment: (fdi: number, removeMode: AttachmentRemoveMode) => void;
    updateAttachmentThickness: (fdi: number, thickness: number) => AttachmentThicknessDto;
  }

  interface WebEventsProxyForAxisAndRoots {
    setTeethRotationAxisPrediction: (data: ToothRotationAxisPrediction[]) => void;
    enableEditionForToothRotationAxis: (enabled: boolean) => void;
    calculateBolton: () => BoltonDataDto;
  }

  interface WebEventsProxyForCommon {
    clearActionCommands: () => void;
    importMaestro3Dv6: (path: string, teethArch: CapturePosition) => void;
    loadModels: (models: Model[]) => Promise<void>;
    simplifyModel: (originPath: string, destinationPath: string) => void;
    unloadModels: (models: string[]) => void;
    exportForPublishing: (caseId: string, outputFolder: string) => Promise<string>;
    removeActionCommands: (commands: ActionCommand[]) => void;
    requestRedo: (command: ActionCommand) => Promise<void>;
    requestUndo: (command: ActionCommand) => Promise<void>;
    setStage: (stage: Stage) => void;
    resetToStage: (stage: Stage) => void;
    resetViewports: () => void;
    setCameraPosition: (cameraPosition: CameraPosition) => void;
    setEditionMode: (isEditing: boolean) => void;
  }

  interface WebEventsProxyForDentalMovements {
    addNewStep: (sourceStepIndex: number) => Promise<void>;
    getInputForIntermediateSteps: (stepIndexFrom: number, stepIndexTo: number) => Promise<InputIntermediateSteps>;
    getToothTransform: (toothFdi: number) => ToothTransformInfo;
    getMovementsTable: () => Promise<MovementsTableDto>;
    getTreatmentMovements: () => { upperMovements: DentalMovementGroup; lowerMovements: DentalMovementGroup };
    goToStep: (stepIndex: number, isComparer: boolean) => void;
    goToTemplate: (stepIndex: number, isComparer: boolean) => void;
    overlapMovements: (teethArch: CapturePosition, stepIndex: number, isComparer: boolean) => void;
    processIntermediateSteps: () => Promise<IntermediateStepsProcessingResult>;
    calculateInterdentalDistancesForStep: (stepIndex: number) => Promise<IntermediateStepsProcessingResult>;
    removeStep: (stepIndex: number) => Promise<void>;
    selectTool: (kind: MovementType) => void;
    setMovements: (dentalMovementGroup: DentalMovementGroup, isComparer: boolean) => Promise<void>;
    setToothChange: (toothChange: ToothChange) => void;
    showStepOverlap: (teethArch: CapturePosition, isvisible: boolean, isComparer: boolean) => void;
    updateIntermediateStepsFromAI: (
      response: OutputIntermediateSteps,
      stepIndexFrom: number,
      stepIndexTo: number
    ) => Promise<void>;
    updateMovements: (dentalMovementPair: DentalMovementPair) => void;
    setStepAsStepWithNoAutoRecalc: (stepIndex: number, isStepWithNoAutoRecalc: boolean) => void;
    resetArch: (archToReset: ResetArch) => void;
  }

  interface WebEventsProxyForExtractions {
    markToothAsExtracted: (toothFdi: number) => Tooth;
  }

  interface WebEventsProxyForIprs {
    moveLabel: (leftFdi: number, rightFdi: number, toStepIndex: number) => IprLabel;
    removeLabel: (leftFdi: number, rightFdi: number) => IprLabel;
    updateLabel: (leftFdi: number, rightFdi: number, value: number) => IprLabel;
  }

  interface WebEventsProxyForSurgeries {
    loadMatchingInfo: (matchingInfo: MatchingInfo) => void;
    selectMatchingColor: (color: Color) => void;
  }

  interface WebEventsProxy {
    attachments: WebEventsProxyForAttachments;
    axisAndRoots: WebEventsProxyForAxisAndRoots;
    common: WebEventsProxyForCommon;
    extractions: WebEventsProxyForExtractions;
    iprs: WebEventsProxyForIprs;
    layers: WebEventsProxyForLayers;
    movements: WebEventsProxyForDentalMovements;
    segmentation: WebEventsProxyForSegmentation;
    surgeries: WebEventsProxyForSurgeries;
  }
}

function addCustomEvents() {
  // Events from Evergine to React
  window.App.appEventsListener.attachments = {} as AppEventsListenerForAttachments;
  window.App.appEventsListener.axisAndRoots = {} as AppEventsListenerForAxisAndRoots;
  window.App.appEventsListener.common = {} as AppEventsListenerForCommon;
  window.App.appEventsListener.movements = {} as AppEventsListenerForDentalMovements;
  window.App.appEventsListener.iprs = {} as AppEventsListenerForIprs;
  window.App.appEventsListener.segmentation = {} as AppEventsListenerForSegmentation;
  window.App.appEventsListener.surgeries = {} as AppEventsListenerForSurgeries;
  window.App.webEventsProxy.attachments = {} as WebEventsProxyForAttachments;
  window.App.webEventsProxy.axisAndRoots = {} as WebEventsProxyForAxisAndRoots;
  window.App.webEventsProxy.common = {} as WebEventsProxyForCommon;
  window.App.webEventsProxy.extractions = {} as WebEventsProxyForExtractions;
  window.App.webEventsProxy.iprs = {} as WebEventsProxyForIprs;
  window.App.webEventsProxy.layers = {} as WebEventsProxyForLayers;
  window.App.webEventsProxy.movements = {} as WebEventsProxyForDentalMovements;
  window.App.webEventsProxy.segmentation = {} as WebEventsProxyForSegmentation;
  window.App.webEventsProxy.surgeries = {} as WebEventsProxyForSurgeries;
  const commandInvokerService = container.get<ICommandInvoker>(INJECTED_TYPES.ICommandInvokerService);

  window.App.appEventsListener.common.onChangeBusyState = (busy: boolean): void => {
    useBoundStore.setState({ evergineBusy: busy });
  };

  window.App.appEventsListener.surgeries.onChangeMatchingPoint = (matchingModelPoint: MatchingModelPoint): void => {
    useBoundStore.setState({ matchingModelPoint: matchingModelPoint });
  };

  window.App.appEventsListener.common.onNewActionCommand = (command: ActionCommand): void => {
    const newCommand = new EvergineCommand(command.id);
    commandInvokerService.addEvergineCommand(newCommand);
  };

  window.App.appEventsListener.iprs.onIprLabelPositionChanged = (iprLabelPosition: IprLabelPosition): void => {
    // console.log('onIprLabelPositionChanged');
  };

  window.App.appEventsListener.iprs.onLabelAdded = (label: IprLabel) => {
    // console.log('onLabelAdded');
  };

  window.App.appEventsListener.iprs.onLabelHovered = (iprLabelPosition: IprLabelPosition) => {
    // console.log('onLabelHovered');
  };

  window.App.appEventsListener.iprs.onLabelLeft = (iprLabelPosition: IprLabelPosition) => {
    // console.log('onLabelLeft');
  };

  window.App.appEventsListener.iprs.onLabelSelected = (iprLabelPosition: IprLabelPosition) => {
    const { upperMovements, lowerMovements } = window.App.webEventsProxy.movements.getTreatmentMovements();
    useOrthBoundStore.setState({
      upperDentalMovements: upperMovements,
      lowerDentalMovements: lowerMovements,
      iprLabelSelected: iprLabelPosition
    });
  };

  window.App.appEventsListener.axisAndRoots.onRealRootsProcessed = (
    zipFileRoute: string,
    teethArch: CapturePosition
  ) => {
    if (!zipFileRoute) return;

    const stateUpdate: Partial<ReturnType<typeof useOrthBoundStore.getState>> = {};

    if (teethArch === CapturePosition.UPPER) {
      stateUpdate.upperZipFileRoute = zipFileRoute;
    } else if (teethArch === CapturePosition.LOWER) {
      stateUpdate.lowerZipFileRoute = zipFileRoute;
    }

    if (Object.keys(stateUpdate).length > 0) {
      useOrthBoundStore.setState(stateUpdate);
    }
  };

  function updateSelectedTeethTransformData(transformInfo: ToothTransformInfo) {
    const teethTransformDataList: ToothTransformInfo[] = useToothTransformStore.getState().teethTransformDataList;
    const existingTeethIndex = teethTransformDataList.findIndex((l) => l.toothFdi === transformInfo.toothFdi);

    if (existingTeethIndex < 0) {
      teethTransformDataList.push(transformInfo);
    } else {
      teethTransformDataList[existingTeethIndex] = transformInfo;
    }

    debouncedSetState(useToothTransformStore, { selectedTeethTransformData: transformInfo, teethTransformDataList });
  }

  window.App.appEventsListener.movements.onToothTransformChanged = (toothTransform: ToothTransformInfo): void => {
    useOrthBoundStore.setState({ areMadeChangesOnTeeth: true, iprProcessed: false });
    updateSelectedTeethTransformData(toothTransform);
  };

  window.App.appEventsListener.movements.onToothHovered = debounceOutsideComponent(
    (toothHoverChanged: ToothHoverData) => {
      const storeData = useOrthBoundStore.getState().toothHoverData;
      if (storeData?.fdi === toothHoverChanged?.fdi) {
        return;
      }

      useOrthBoundStore.setState({
        toothHoverData: toothHoverChanged,
        isToothHovered: true
      });
    },
    200
  );

  window.App.appEventsListener.movements.onToothLeft = debounceOutsideComponent((toothHoverChanged: ToothHoverData) => {
    useOrthBoundStore.setState({ toothHoverData: toothHoverChanged, isToothHovered: false });
  }, 200);

  window.App.appEventsListener.movements.onTeethSelected = (teethFdis: number[] | undefined | null): void => {
    useOrthBoundStore.setState({ selectedTeethId: teethFdis ?? [], selectedAttache: null });

    teethFdis.forEach((fdi) => {
      const toothTransform = window.App.webEventsProxy.movements.getToothTransform(fdi);
      if (toothTransform != null) {
        updateSelectedTeethTransformData(toothTransform);
      }
    });
  };

  window.App.appEventsListener.attachments.onAttachmentHovered = (info: AttachmentPositionInfo) => {
    const hoveredAttach: TooltipAttach = {
      toothFdi: info.attachmentDto.toothFdi,
      position: {
        x: info.screenPosition.x,
        y: info.screenPosition.y
      },
      type: info.attachmentDto.type,
      thickness: {
        currentThickness: info.thickness.currentThickness,
        canDecrement: info.thickness.canDecrement,
        canIncrement: info.thickness.canIncrement
      }
    };
    debouncedSetState(useOrthBoundStore, { hoveredAttach });
  };

  window.App.appEventsListener.attachments.onAttachmentLeft = (info: AttachmentDto) => {
    const isAttacheSelected = useOrthBoundStore.getState().isAttacheSelected;
    const baseStateChanges: Record<string, any> = { hoveredAttach: null };

    debouncedSetState(
      useOrthBoundStore,
      !isAttacheSelected ? { ...baseStateChanges, selectedAttache: null } : baseStateChanges
    );
  };

  window.App.appEventsListener.attachments.onAttachmentSelected = (info: AttachmentPositionInfo | null) => {
    if (info === null) {
      useOrthBoundStore.setState({
        isAttacheSelected: false,
        selectedAttache: null
      });
    }
    if (info && info.attachmentDto.toothFdi !== 0 && info.attachmentDto.toothFdi !== -1) {
      const selectedAttache: TooltipAttach = {
        toothFdi: info.attachmentDto.toothFdi,
        position: {
          x: info.screenPosition.x,
          y: info.screenPosition.y
        },
        type: info.attachmentDto.type,
        thickness: {
          currentThickness: info.thickness.currentThickness,
          canDecrement: info.thickness.canDecrement,
          canIncrement: info.thickness.canIncrement
        }
      };
      useOrthBoundStore.setState({
        isAttacheSelected: true,
        selectedAttache: selectedAttache,
        selectedTeethId: [info.attachmentDto.toothFdi]
      });
    }
  };

  window.App.appEventsListener.segmentation.onBrushActivationChange = (activated: boolean): void => {
    if (activated) useOrthBoundStore.setState({ isSegmentationApplied: true });
    useOrthBoundStore.setState({ modeBrush: activated });
  };

  window.App.appEventsListener.segmentation.onEraserActivationChange = (activated: boolean): void => {
    useOrthBoundStore.setState({ modeEraser: activated });
  };

  ////Events from React to Evergine

  window.App.webEventsProxy.common.loadModels = (models: Model[]): Promise<void> => {
    return window.Utils.invokeAsync('LoadModelsAsync', models);
  };

  window.App.webEventsProxy.common.simplifyModel = (originPath: string, destinationPath: string) => {
    window.Utils.invoke('SimplifyModel', originPath, destinationPath);
  };

  window.App.webEventsProxy.common.unloadModels = (models: string[]): void => {
    window.Utils.invoke('UnloadModels', models);
  };

  window.App.webEventsProxy.common.setStage = (stage: Stage): void => {
    window.Utils.invoke('SetStage', stage);
  };

  window.App.webEventsProxy.common.resetToStage = (stage: Stage): void => {
    window.Utils.invoke('ResetToStage', stage);
  };

  window.App.webEventsProxy.surgeries.loadMatchingInfo = (matchingInfo: MatchingInfo): void => {
    window.Utils.invoke('LoadMatchingInfo', JSON.stringify(matchingInfo));
  };

  window.App.webEventsProxy.surgeries.selectMatchingColor = (color: Color): void => {
    window.Utils.invoke('SelectMatchingColor', color);
  };

  window.App.webEventsProxy.common.resetViewports = (): void => {
    window.Utils.invoke('ResetViewports');
  };

  window.App.webEventsProxy.common.setCameraPosition = (cameraPosition: CameraPosition): void => {
    window.Utils.invoke('SetCameraPosition', cameraPosition);
  };

  window.App.webEventsProxy.layers.showAxis = (showAxis: boolean): void => {
    window.Utils.invoke('ShowAxis', showAxis);
  };

  window.App.webEventsProxy.layers.showGrid = (enabled: boolean): void => {
    window.Utils.invoke('ShowGrid', enabled);
  };

  window.App.webEventsProxy.common.clearActionCommands = (): void => {
    window.Utils.invoke('ClearActionCommands');
  };

  window.App.webEventsProxy.common.requestUndo = (command: ActionCommand): Promise<void> => {
    return window.Utils.invokeAsync('RequestUndoAsync', command.id);
  };

  window.App.webEventsProxy.common.requestRedo = (command: ActionCommand): Promise<void> => {
    return window.Utils.invokeAsync('RequestRedoAsync', command.id);
  };

  window.App.webEventsProxy.common.removeActionCommands = (commands: ActionCommand[]): void => {
    const ids = commands.map((cmd) => cmd.id);
    if (ids.length > 0) {
      window.Utils.invoke('RemoveActionCommands', ids);
    }
  };

  window.App.webEventsProxy.common.importMaestro3Dv6 = (path: string, teethArch: CapturePosition): void => {
    window.Utils.invoke('ImportMaestro3Dv6', path, teethArch);
  };

  window.App.webEventsProxy.movements.setMovements = (
    dentalMovementGroup: DentalMovementGroup,
    isComparer: boolean
  ): Promise<void> => {
    return window.Utils.invokeAsync('SetDentalMovementsAsync', dentalMovementGroup, isComparer);
  };

  window.App.webEventsProxy.movements.updateMovements = (dentalMovementPair: DentalMovementPair): void => {
    window.Utils.invoke('UpdateDentalMovements', dentalMovementPair);
    useOrthBoundStore.setState({ iprProcessed: false });
  };

  window.App.webEventsProxy.movements.setStepAsStepWithNoAutoRecalc = (
    stepIndex: number,
    isStepWithNoAutoRecalc: boolean
  ): void => {
    window.Utils.invoke('SetStepAsStepWithNoAutoRecalcAsync', stepIndex, isStepWithNoAutoRecalc);
  };

  window.App.webEventsProxy.movements.showStepOverlap = (
    teethArch: CapturePosition,
    isVisible: boolean,
    isComparer: boolean
  ): void => {
    window.Utils.invoke('ShowStepOverlap', teethArch, isVisible, isComparer);
  };

  window.App.webEventsProxy.movements.overlapMovements = (
    teethArch: CapturePosition,
    stepIndex: number,
    isComparer: boolean
  ): void => {
    window.Utils.invoke('OverlapDentalMovements', teethArch, stepIndex, isComparer);
  };

  window.App.webEventsProxy.movements.goToStep = (stepIndex: number, isComparer: boolean): void => {
    window.Utils.invoke('GoToStepDentalMovement', stepIndex, !!isComparer);

    const teethFdi = useOrthBoundStore.getState().selectedTeethId;
    teethFdi.forEach((toothFdi) => {
      if (toothFdi > 0) {
        const toothTransform = window.App.webEventsProxy.movements.getToothTransform(toothFdi);
        if (toothTransform != null) {
          updateSelectedTeethTransformData(toothTransform);
        }
      }
    });
  };

  window.App.webEventsProxy.movements.goToTemplate = (stepIndex: number, isComparer: boolean): void => {
    window.Utils.invoke('GoToTemplateDentalMovement', stepIndex, !!isComparer);

    const teethFdi = useOrthBoundStore.getState().selectedTeethId;
    teethFdi.forEach((toothFdi) => {
      if (toothFdi > 0) {
        const toothTransform = window.App.webEventsProxy.movements.getToothTransform(toothFdi);
        if (toothTransform != null) {
          updateSelectedTeethTransformData(toothTransform);
        }
      }
    });
  };

  window.App.webEventsProxy.movements.getToothTransform = (toothFdi: number): ToothTransformInfo => {
    return window.Utils.invoke('GetToothTransform', toothFdi);
  };

  window.App.webEventsProxy.movements.getMovementsTable = (): Promise<MovementsTableDto> => {
    return window.Utils.invokeAsync('GetMovementsTable');
  };

  window.App.webEventsProxy.movements.addNewStep = (sourceStepIndex: number): Promise<void> => {
    return window.Utils.invokeAsync('AddNewStepAsync', sourceStepIndex);
  };

  window.App.webEventsProxy.movements.removeStep = (stepIndex: number): Promise<void> => {
    return window.Utils.invokeAsync('RemoveStepAsync', stepIndex);
  };

  window.App.webEventsProxy.movements.getInputForIntermediateSteps = (
    stepIndexFrom: number,
    stepIndexTo: number
  ): Promise<InputIntermediateSteps> => {
    return window.Utils.invokeAsync('GetInputForIntermediateStepsAsync', stepIndexFrom, stepIndexTo);
  };

  window.App.webEventsProxy.movements.updateIntermediateStepsFromAI = (
    response: OutputIntermediateSteps,
    stepIndexFrom: number,
    stepIndexTo: number
  ): Promise<void> => {
    return window.Utils.invokeAsync('UpdateIntermediateStepsFromAIAsync', response, stepIndexFrom, stepIndexTo);
  };

  window.App.webEventsProxy.movements.resetArch = (archToReset: ResetArch): void => {
    window.Utils.invoke('ResetArchMovements', archToReset);
  };

  window.App.webEventsProxy.layers.showAttachments = (show: boolean): void => {
    window.Utils.invoke('ShowAttachments', show);
  };

  window.App.webEventsProxy.layers.showArches = (arches: CapturePosition): void => {
    window.Utils.invoke('ShowArches', arches);
  };

  window.App.webEventsProxy.layers.showOcclusogram = (show: boolean): void => {
    window.Utils.invoke('ShowOcclusogram', show);
  };

  window.App.webEventsProxy.layers.setOcclusogramBlend = (blend: number): void => {
    window.Utils.invoke('SetOcclusogramBlend', blend);
  };

  window.App.webEventsProxy.layers.showIprs = (show: boolean): void => {
    window.Utils.invoke('ShowIprs', show);
  };

  window.App.webEventsProxy.layers.showInterdentalDistances = (show: boolean): void => {
    window.Utils.invoke('ShowInterdentalDistances', show);
  };

  window.App.webEventsProxy.layers.showRoots = (show: boolean): void => {
    window.Utils.invoke('ShowRoots', show);
  };

  window.App.webEventsProxy.layers.showGums = (show: boolean): void => {
    window.Utils.invoke('ShowGums', show);
  };

  window.App.webEventsProxy.layers.showTeeth = (show: boolean): void => {
    window.Utils.invoke('ShowTeeth', show);
  };

  window.App.webEventsProxy.layers.showTeethAxis = (show: boolean): void => {
    window.Utils.invoke('ShowTeethAxis', show);
  };

  window.App.webEventsProxy.layers.setGumsOpacity = (value: number): void => {
    window.Utils.invoke('SetGumsOpacity', value);
  };

  window.App.webEventsProxy.layers.setRootsOpacity = (value: number): void => {
    window.Utils.invoke('SetRootsOpacity', value);
  };

  window.App.webEventsProxy.layers.setTeethOpacity = (value: number): void => {
    window.Utils.invoke('SetTeethOpacity', value);
  };

  window.App.webEventsProxy.layers.showTADs = (show: boolean): void => {
    window.Utils.invoke('ShowTADs', show);
  };

  window.App.webEventsProxy.movements.setToothChange = (toothChange: ToothChange): void => {
    window.Utils.invoke('SetToothChange', toothChange);
  };

  window.App.webEventsProxy.movements.selectTool = (kind: MovementType): void => {
    window.Utils.invoke('SelectTool', kind);
  };

  window.App.webEventsProxy.common.setEditionMode = (isEditing: boolean): void => {
    window.Utils.invoke('SetEditionMode', isEditing);
  };

  window.App.webEventsProxy.segmentation.applyNumerationChange = (
    fdiFrom: number,
    fdiTo: number,
    strategy: SegmentationNumerationStrategy
  ): Promise<void> => {
    return window.Utils.invokeAsync('ApplySegmentationNumerationChangeAsync', fdiFrom, fdiTo, strategy);
  };

  window.App.webEventsProxy.segmentation.evaluateNumerationChange = (
    fdiFrom: number,
    fdiTo: number,
    strategy: SegmentationNumerationStrategy
  ): Promise<SegmentationNumerationChangeEvalResult> => {
    return window.Utils.invokeAsync('EvaluateSegmentationNumerationChangeAsync', fdiFrom, fdiTo, strategy);
  };

  window.App.webEventsProxy.segmentation.processSegmentation = (): Promise<SegmentationProcessingResult> => {
    return window.Utils.invokeAsync('ProcessSegmentationAsync');
  };

  window.App.webEventsProxy.segmentation.setBrushSize = (size: number): void => {
    window.Utils.invoke('SetSegmentationBrushSize', size);
  };

  window.App.webEventsProxy.segmentation.setColorsByFdi = (colors: { [fdi: number]: string }): void => {
    window.Utils.invoke('SetSegmentationColorsByFdi', colors);
  };

  window.App.webEventsProxy.segmentation.setPainterFdi = (fdi: number): void => {
    window.Utils.invoke('SetSegmentationPainterFdi', fdi);
  };

  window.App.webEventsProxy.segmentation.setSegmentationData = (data: {
    faces: number[];
    teethArch: CapturePosition;
  }): void => {
    window.Utils.invoke('SetSegmentationData', data);
  };

  window.App.webEventsProxy.segmentation.setInitialSegmentationData = (data: {
    faces: number[];
    teethArch: CapturePosition;
  }): void => {
    window.Utils.invoke('SetInitialSegmentationData', data);
  };

  window.App.webEventsProxy.segmentation.queryToothFDI = (): number => {
    return window.Utils.invoke('QueryToothFDI');
  };

  window.App.webEventsProxy.axisAndRoots.setTeethRotationAxisPrediction = (
    data: ToothRotationAxisPrediction[]
  ): void => {
    window.Utils.invoke('SetTeethRotationAxisPrediction', data);
  };

  window.App.webEventsProxy.axisAndRoots.enableEditionForToothRotationAxis = (enabled = false): void => {
    window.Utils.invoke('EnableEditionForToothRotationAxis', enabled);
  };

  window.App.webEventsProxy.axisAndRoots.calculateBolton = (): BoltonDataDto => {
    return window.Utils.invoke('CalculateBolton');
  };

  window.App.webEventsProxy.extractions.markToothAsExtracted = (toothFdi: number): Tooth => {
    return window.Utils.invoke('MarkToothAsExtracted', toothFdi);
  };

  window.App.webEventsProxy.movements.getTreatmentMovements = (): {
    upperMovements: DentalMovementGroup;
    lowerMovements: DentalMovementGroup;
  } => {
    return window.Utils.invoke('GetTreatmentMovements');
  };

  window.App.webEventsProxy.movements.processIntermediateSteps = (): Promise<IntermediateStepsProcessingResult> => {
    return window.Utils.invokeAsync('ProcessIntermediateStepsAsync');
  };

  window.App.webEventsProxy.movements.calculateInterdentalDistancesForStep = (
    stepIndex: number
  ): Promise<IntermediateStepsProcessingResult> => {
    return window.Utils.invokeAsync('CalculateInterdentalDistancesForStep', stepIndex);
  };

  window.App.webEventsProxy.iprs.moveLabel = (leftFdi: number, rightFdi: number, toStepIndex: number): IprLabel => {
    return window.Utils.invoke('MoveIprLabel', leftFdi, rightFdi, toStepIndex);
  };

  window.App.webEventsProxy.iprs.removeLabel = (leftFdi: number, rightFdi: number): IprLabel => {
    useOrthBoundStore.setState({ iprLabelSelected: undefined });
    return window.Utils.invoke('RemoveIprLabel', leftFdi, rightFdi);
  };

  window.App.webEventsProxy.iprs.updateLabel = (leftFdi: number, rightFdi: number, value: number): IprLabel => {
    return window.Utils.invoke('UpdateIprLabel', leftFdi, rightFdi, value);
  };

  window.App.webEventsProxy.attachments.getInputAttachments = (): Promise<InputAttachments> => {
    return window.Utils.invokeAsync('GetInputAttachments');
  };

  window.App.webEventsProxy.attachments.updateAttachmentsFromAI = (attachments: OutputAttachments): Promise<void> => {
    return window.Utils.invokeAsync('UpdateAttachmentsFromAIAsync', attachments);
  };

  window.App.webEventsProxy.attachments.addAttachment = (fdi: number, type: AttachmentType): AttachmentDto => {
    return window.Utils.invoke('AddAttachment', fdi, type);
  };

  window.App.webEventsProxy.attachments.changeAttachmentType = (fdi: number, type: AttachmentType): AttachmentDto => {
    return window.Utils.invoke('ChangeAttachmentType', fdi, type);
  };

  window.App.webEventsProxy.attachments.removeAttachment = (fdi: number, removeMode: AttachmentRemoveMode): void => {
    window.Utils.invoke('RemoveAttachment', fdi, removeMode);
  };

  window.App.webEventsProxy.attachments.updateAttachmentThickness = (
    fdi: number,
    thickness: number
  ): AttachmentThicknessDto => {
    return window.Utils.invoke('UpdateAttachmentThickness', fdi, thickness);
  };

  window.App.webEventsProxy.common.exportForPublishing = (caseId: string, outputFolder: string): Promise<string> => {
    return window.Utils.invokeAsync('ExportForPublishingAsync', caseId, outputFolder);
  };
}

let originalInvokeAsync: <T = void>(methodName: string, ...args: unknown[]) => Promise<T>;
let originalInvoke: <T = void>(methodName: string, ...args: unknown[]) => T;
let errorHandlerService: IErrorHandlerService;

const initializeEvergine = (): void => {
  initializeEvergineBase(EVERGINE_LOADING_BAR_ID, EVERGINE_ASSEMBLY_NAME, EVERGINE_CLASS_NAME, addCustomEvents);
  interceptInvokesToWasm();
};

function interceptInvokesToWasm() {
  errorHandlerService = container.get<IErrorHandlerService>(INJECTED_TYPES.IErrorHandlerService);
  originalInvokeAsync = !!originalInvokeAsync ? originalInvokeAsync : window.Utils.invokeAsync;
  originalInvoke = !!originalInvoke ? originalInvoke : window.Utils.invoke;
  window.Utils.invokeAsync = interceptedInvokeAsync;
  window.Utils.invoke = interceptedInvoke;
}

async function interceptedInvokeAsync<T = void>(methodName: string, ...args: unknown[]): Promise<T> {
  try {
    // console.log('*** interceptedInvokeAsync call: ', methodName, args);
    const result: T = await originalInvokeAsync<T>(methodName, ...args);
    // console.log('*** interceptedInvokeAsync result: ', methodName, result);
    return result;
  } catch (error) {
    errorHandlerService.showError(error);
  }
}

function interceptedInvoke<T = void>(methodName: string, ...args: unknown[]): T {
  try {
    // console.log('*** interceptedInvoke call: ', methodName, args);
    const result: T = originalInvoke<T>(methodName, ...args);
    // console.log('*** interceptedInvoke result: ', methodName, result);
    return result;
  } catch (error) {
    errorHandlerService.showError(error);
  }
}

export { initializeEvergine };
