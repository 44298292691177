import { useTranslation } from 'react-i18next';
import { ReactComponent as Info } from '../../../../../assets/icons/modal/info.svg';
import { useCaseChanges } from '../../../../../hooks';
import { useIprCalculation } from '../../../../../hooks/shared/useIprCalculation';
import { useSaveAndNavigateStep } from '../../../../../hooks/shared/useSaveAndNavigateStep';
import { useOrthBoundStore } from '../../../../../orthodontics/stores/useStore';

type ModalPendingIprCalculationTypes = {
  nextRoute?: string;
};

export function ModalPendingIprCalculation({ nextRoute }: ModalPendingIprCalculationTypes) {
  const [t] = useTranslation();
  const { toggleModalIsOpened } = useCaseChanges();
  const { calculateIpr } = useIprCalculation();
  const { saveAndNavigateStep } = useSaveAndNavigateStep({ isDirectNavigation: true, checkPhaseChanges: false });
  const { isNavigationToNextPhase, navigationRoute } = useOrthBoundStore();

  const onCancel = () => {
    toggleModalIsOpened('');
  };

  const goToNextStep = async () => {
    saveAndNavigateStep(navigationRoute, isNavigationToNextPhase, false, false, false);
  };

  const handleContinueWithCalc = async () => {
    toggleModalIsOpened('');
    await calculateIpr();

    await goToNextStep();
  };

  const handleContinueWithoutCalc = async () => {
    toggleModalIsOpened('');
    await goToNextStep();
  };

  return (
    <div className="modal-content" data-testid={'modal-pendingIprCalculation'}>
      <div className="modal-body d-flex flex-column align-items-center">
        <Info className="modal-icon mb-3" />
        <h3 className="modal-title mb-4">{t(`modal.pendingIprCalculation.title`)}</h3>
        <p className="modal-text text-center mb-0">{t(`modal.pendingIprCalculation.description`)}</p>
      </div>

      <div className="modal-footer d-flex flex-grow-1">
        <button
          type="button"
          onClick={onCancel}
          className="btn btn-outline-primary flex-fill modal-button"
          data-bs-dismiss="modal"
        >
          {t(`modal.pendingIprCalculation.cancel`)}
        </button>
        <button onClick={handleContinueWithCalc} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.pendingIprCalculation.confirm`)}
        </button>
        <button onClick={handleContinueWithoutCalc} type="button" className="btn btn-primary flex-fill modal-button">
          {t(`modal.pendingIprCalculation.continue`)}
        </button>
      </div>
    </div>
  );
}
